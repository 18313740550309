import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ImageMdoalComponent } from '../image-mdoal/image-mdoal.component';
import { AuthService } from './../auth.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})

export class CreateProjectComponent implements OnInit, OnDestroy {
  createProjectForm: FormGroup;
  expenseForm: FormGroup;
  sectionsArray: FormArray;
  productsArray: FormArray;
  hardwaresArray: FormArray;
  appliancesArray: FormArray;
  expenseArray: FormArray;

  types = [];
  sections = [];
  products = [];
  hardwares = [];
  appliances = [];

  customers = [];
  customerAddresses = [];

  designers = [];

  panelOpenState = false;

  increasedProductPricePercent = 0;
  totalProductsAmount = 0;
  totalHardwareAmount = 0;
  totalProductsMargin = 0;
  totalHardwareMargin = 0;
  totalProjectAmount = 0;
  totalProjectMargin = 0;
  totalExpense = 0;
  totalProjectSqft = 0;

  hardwareStep = false;
  applianceStep = false;

  showSectionDiv = true;
  addSectionAfterForHardware = false;
  addSectionAfterForAppliance = false;

  sectionIndex = 0;

  projectID: string;

  isAdmin = false;
  isManager = false;
  isDesigner = false;

  errorMsg: string;

  isLoading = false;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    combineLatest([
      this.route.queryParams,
      this.route.params,
      this.fetchSections(),
      this.fetchCustomers(),
      this.fetchDesigners(),
      this.fetchProjectTypes(),
      this.fetchProducts({}),
      this.fetchHardwares({}),
      this.fetchAppliances({}),
    ])
      .pipe(
        tap(([{ contact }, { id }]) => {
          this.initializeForm();

          let prodDiscountControl = this.expenseForm.get(
            'finalProductTotalDiscount'
          ) as FormControl;
          let hardDiscountControl = this.expenseForm.get(
            'finalHardwareTotalDiscount'
          ) as FormControl;
          const currentUser = this.authService.currentUserValue;
          if (currentUser.roles.indexOf('admin') !== -1) {
            this.isAdmin = true;
          } else if (currentUser.roles.indexOf('designer') !== -1) {
            this.isDesigner = true;
            prodDiscountControl.setValidators(Validators.max(10));
            hardDiscountControl.setValidators(Validators.max(10));
            this.errorMsg = 'Cannot give more than 10%';
          } else if (currentUser.roles.indexOf('manager') !== -1) {
            this.isManager = true;
            prodDiscountControl.setValidators(Validators.max(15));
            hardDiscountControl.setValidators(Validators.max(15));
            this.errorMsg = 'Cannot give more than 15%';
          }

          if (contact) {
            this.createProjectForm.patchValue({
              phone: contact,
            });
            this.onPopulateCustomer(contact);
          }

          if (id) {
            this.projectID = id;

            this.apiService
              .listProjectById(this.projectID)
              .pipe(
                tap((res) => {
                  if (res && res.data) {
                    const project = res.data;

                    this.onPopulateCustomer(project.phone);

                    const sections = [...project.sections];
                    this.sectionsArray = this.createProjectForm.get('sections') as FormArray;

                    sections.forEach((s, index) => {
                      this.sectionsArray.push(this.sectionForm(''));
                      
                      const sectionProducts = s.products.filter((sProds) =>
                        this.products.find((p) => p._id === sProds.productID)
                      );

                      sectionProducts.forEach((p) => {
                        const product = this.products.find(
                          (pr) => pr._id === p.productID
                        );

                        p.materials = [...product.materials];
                        product.materials.forEach((m) => {
                          if (m.name === p.productMaterial) {
                            p.materialTypes = [...m.materialTypes];
                            p.productMaterialId = m._id; // Set productMaterialId
                            m.materialTypes.forEach((t) => {
                              if (t.name === p.productMaterialType) {
                                p.productMaterialTypeId = t._id; // Set productMaterialTypeId
                              }
                            });
                          }
                        });
                        this.onAddProduct(p, index); // Pass the product 'p'
                        this.calculateSectionTotal(index);
                      });


                      const sectionHardwares = s.hardwares.filter(
                        (sHardwares) =>
                          this.hardwares.find(
                            (h) => h._id === sHardwares.hardwareID
                          )
                      );

                      const sectionAppliances = s.appliances.filter(
                        (sAppliances) =>
                          this.appliances.find(
                            (a) => a._id === sAppliances.applianceID
                          )
                      );

                      // sectionProducts.forEach((p) => {
                      //   const product = this.products.find(
                      //     (pr) => pr._id === p.productID
                      //   );

                      //   p.materials = [...product.materials];
                      //   product.materials.forEach((m) => {
                      //     if (m.name === p.productMaterial) {
                      //       p.materialTypes = [...m.materialTypes];
                      //     }
                      //   });
                      //   this.onAddProduct('', index);
                      // });

                      sectionHardwares.forEach((h) => {
                        const hardware = this.hardwares.find(
                          (hr) => hr._id === h.hardwareID
                        );

                        h.hardwareTypes = [...hardware.hardwareType];
                        this.onAddHardware('', index);
                      });

                      if (s.appliances.length > 0) {
                        sectionAppliances.forEach((a) => {
                          const appliance = this.appliances.find(
                            (app) => app._id === a.applianceID
                          );

                          a.applianceTypes = [...appliance.appliancesType];
                          this.onAddAppliance('', index);
                        });
                      }
                    });

                    const expenses = [...project.expenses];
                    if (expenses.length > 0) {
                      expenses.forEach((e) => {
                        this.onAddExpenseRow();
                      });
                    }

                    this.createProjectForm.patchValue(project);

                    this.expenseForm.patchValue({
                      expenses: expenses,
                      increasedProductPricePercent: project.increasedProductPricePercent,
                      totalExpense: project.totalExpense,
                      finalProductTotalDiscount:
                        project.finalProductTotalDiscount,
                      finalHardwareTotalDiscount:
                        project.finalHardwareTotalDiscount,
                      gstPercent: project.gstPercent,
                    });

                    this.increasedProductPricePercent = project.increasedProductPricePercent || 0;

                    this.calculateProjectTotal();
                    this.calculateExpenseTotal();
                  } else {
                    this.matSnackBar.open(
                      'Some Problem Occurred.. Try Again !',
                      'OK',
                      {
                        duration: 3000,
                      }
                    );
                  }
                })
              )
              .subscribe();
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => (this.isLoading = false));
  }

  initializeForm() {
    this.createProjectForm = new FormGroup({
      customerID: new FormControl('', Validators.required),
      customerName: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      projectAddressID: new FormControl('', Validators.required),
      projectAddress: new FormControl('', Validators.required),
      designerID: new FormControl('', Validators.required),
      designerName: new FormControl('', Validators.required),
      desginerEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      desginerContact: new FormControl('', Validators.required),
      projectTypeID: new FormControl('', Validators.required),
      projectType: new FormControl('', Validators.required),
      sections: new FormArray([], Validators.required),
      finalProductTotal: new FormControl(''),
      finalHardwareTotal: new FormControl(''),
      finalProductMargin: new FormControl(''),
      finalHardwareMargin: new FormControl(''),
      totalAmount: new FormControl('', Validators.required),
      totalMargin: new FormControl('', Validators.required),
    });

    this.expenseForm = new FormGroup({
      expenses: new FormArray([]),
      totalExpense: new FormControl(''),
      increasedProductPricePercent: new FormControl(0),
      finalProductTotalDiscount: new FormControl(0),
      finalHardwareTotalDiscount: new FormControl(0),
      gstPercent: new FormControl(0),
    });
  }

  sectionForm(section) {
    const sectionForm = new FormGroup({
      sectionID: new FormControl('', Validators.required),
      sectionName: new FormControl('', Validators.required),
      products: new FormArray([], Validators.required),
      hardwares: new FormArray([]),
      appliances: new FormArray([]),
      defaultHardwares: new FormControl([]),
      defaultAppliances: new FormControl([]),
      productsTotal: new FormControl(''),
      hardwaresTotal: new FormControl(''),
      productsMargin: new FormControl(''),
      hardwareMargin: new FormControl(''),
      totalSectionMargin: new FormControl('', Validators.required),
      total: new FormControl('', Validators.required),
      discount: new FormControl(''),
      totalSqft: new FormControl(0), // Initialize with 0
      unit: new FormControl('', Validators.required),
    });

    if (section) {
      sectionForm.patchValue({
        sectionID: section._id,
        sectionName: section.name,
        defaultHardwares: [...section.default_hardwares.map((h) => h._id)],
        defaultAppliances: [...section.default_appliances.map((a) => a._id)],
      });
    }

    return sectionForm;
  }

  productsForm(product) {
    const productForm = new FormGroup({
      productID: new FormControl('', Validators.required),
      productName: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
      materials: new FormControl([]),
      materialTypes: new FormControl([]),
      productMaterial: new FormControl('', Validators.required),
      productMaterialId: new FormControl(''),
      productMaterialType: new FormControl('', Validators.required),
      productMaterialTypeId: new FormControl(''),
      price: new FormControl('', Validators.required),
      mrp: new FormControl('', Validators.required),
      commission: new FormControl('', Validators.required),
      commissionType: new FormControl(''),
      // tax: new FormControl(''),
      unit: new FormControl('', Validators.required), // Ensure unit is present
      height: new FormControl(1),
      width: new FormControl(1),
      count: new FormControl(1),
      total: new FormControl('', Validators.required),
      description: new FormControl(''),
      increasedProductPricePercent: new FormControl(this.expenseForm.get('increasedProductPricePercent').value), 
    });

    if (product) {
      productForm.patchValue({
        productID: product.productID,
        productName: product.productName,
        image: product.image,
        materials: product.materials,
        materialTypes: product.materialTypes,
        productMaterial: product.productMaterial,
        productMaterialId: product.productMaterialId,
        productMaterialType: product.productMaterialType,
        productMaterialTypeId: product.productMaterialTypeId,
        price: product.price,
        mrp: product.mrp,
        commission: product.commission,
        commissionType: product.commissionType,
        unit: product.unit,
        height: product.height,
        width: product.width,
        count: product.count,
        total: product.total,
        description: product.description,
        increasedProductPricePercent: product.increasedProductPricePercent || this.expenseForm.get('increasedProductPricePercent').value,
      });
    }

    return productForm;
  }

  hardwareForm(hardware) {
    const hardwareForm = new FormGroup({
      hardwareID: new FormControl('', Validators.required),
      hardwareName: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
      hardwareTypes: new FormControl([]),
      hardwareType: new FormControl(''),
      price: new FormControl('', Validators.required),
      mrp: new FormControl('', Validators.required),
      commission: new FormControl('', Validators.required),
      // tax: new FormControl(''),
      height: new FormControl(1),
      width: new FormControl(1),
      count: new FormControl(1),
      total: new FormControl('', Validators.required),
      productID: new FormControl(''),
      productName: new FormControl(''),
    });

    if (hardware) {
      hardwareForm.patchValue({
        hardwareID: hardware._id,
        hardwareName: hardware.name,
        image: hardware.image,
        hardwareTypes: hardware.hardwareType,
        price: hardware.price,
        mrp: hardware.mrp,
        commission: hardware.commission,
        total: hardware.mrp,
      });
    }

    return hardwareForm;
  }

  applianceForm(appliance) {
    const applianceForm = new FormGroup({
      applianceID: new FormControl('', Validators.required),
      applianceName: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
      applianceTypes: new FormControl([]),
      applianceType: new FormControl(''),
      price: new FormControl('', Validators.required),
      mrp: new FormControl('', Validators.required),
      commission: new FormControl('', Validators.required),
      // tax: new FormControl(''),
      height: new FormControl(1),
      width: new FormControl(1),
      count: new FormControl(1),
      discount: new FormControl(0),
      total: new FormControl('', Validators.required),
    });

    if (appliance) {
      applianceForm.patchValue({
        applianceID: appliance._id,
        applianceName: appliance.name,
        image: appliance.image,
        applianceTypes: appliance.appliancesType,
        price: appliance.price,
        mrp: appliance.mrp,
        commission: appliance.commission,
        total: appliance.mrp,
      });
    }

    if (this.isDesigner) {
      applianceForm.get('discount').setValidators(Validators.max(10));
    }

    if (this.isManager) {
      applianceForm.get('discount').setValidators(Validators.max(15));
    }

    return applianceForm;
  }

  getExpenseForm() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      price: new FormControl('', Validators.required),
    });
  }

  validateDecimalInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);
    const currentValue = (event.target as HTMLInputElement).value;

    // Allow only digits and one decimal point
    if (!/^\d*\.?\d*$/.test(currentValue + inputChar)) {
      event.preventDefault();
    }
  }

  fetchSections() {
    return this.apiService.listSections().pipe(
      tap((res) => {
        if (res && res.data) {
          this.sections = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchCustomers() {
    return this.apiService.listCustomers({}).pipe(
      tap((res) => {
        if (res && res.data) {
          this.customers = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchDesigners() {
    return this.apiService.listUserByRole({ roles: ['designer', 'admin', 'user' ] }).pipe(
      tap((res) => {
        if (res && res.data) {
          this.designers = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchProjectTypes() {
    return this.apiService.listProjectTypes().pipe(
      tap((res) => {
        if (res && res.data) {
          this.types = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchProducts(data) {
    return this.apiService.listProducts(data).pipe(
      tap((res) => {
        if (res && res.data) {
          this.products = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchHardwares(data) {
    return this.apiService.listHardwares(data).pipe(
      tap((res) => {
        if (res && res.data) {
          this.hardwares = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  fetchAppliances(data) {
    return this.apiService.listAppliances(data).pipe(
      tap((res) => {
        if (res && res.data) {
          this.appliances = res.data;
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      })
    );
  }

  onSelectCustomer(event) {
    if (event && event.value) {
      const customer = this.customers.find((c) => c._id === event.value);
      this.createProjectForm.patchValue({
        customerName: customer.firstName + ' ' + customer.lastName,
        phone: customer.phone,
      });
      this.customerAddresses = customer.address;
    }
  }

  onSelectDesigner(event) {
    if (event && event.value) {
      const designer = this.designers.find((d) => d._id === event.value);
      if (designer) {
        this.createProjectForm.patchValue({
          designerID: designer._id,
          designerName: designer.firstName + ' ' + designer.lastName,
          desginerContact: designer.phone, 
          desginerEmail: designer.email,
        });
      }
    }
  }

  displayFn(customer): string {
    return customer && customer.firstName && customer.lastName
      ? customer.firstName + ' ' + customer.lastName
      : '';
  }

  onPopulateCustomer(phoneNumber) {
    this.apiService
      .listCustomerByPhone(phoneNumber)
      .pipe(
        tap((res) => {
          if (res && res.data) {
            const customer = res.data;
            if (customer) {
              this.createProjectForm.patchValue({
                customerID: customer._id,
                customerName: customer.firstName + ' ' + customer.lastName,
              });
              this.customerAddresses = customer.address;
            }
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  onSelectAddress(address) {
    const projAddress =
      address.building +
      (address.street ? ', ' + address.street : '') +
      (address.locality ? ', ' + address.locality : '') +
      (address.landmark ? ', ' + address.landmark : '') +
      ', PIN: ' +
      address.pin;
    this.createProjectForm.patchValue({
      projectAddress: projAddress,
    });
  }

  onSelectProjectType(event) {
    if (event && event.value) {
      const pType = this.types.find((t) => t._id === event.value);
      this.createProjectForm.patchValue({
        projectType: pType.name,
      });

      this.sectionsArray = this.createProjectForm.get('sections') as FormArray;
      while (this.sectionsArray.length > 0) {
        this.sectionsArray.removeAt(0);
      }

      const sectionsInType = pType.defaultSections;
      sectionsInType.forEach((sId, index) => {
        this.apiService.listSectionById(sId).subscribe((res) => {
          this.onAddSection(res.data, index, 'before');
        });
      });
    }
  }

  onAddSection(section, index, mode) {
    if (mode === 'after') {
      this.sectionIndex = index;
      this.addSectionAfterForHardware = true;
      this.addSectionAfterForAppliance = true;
    }

    this.sectionsArray = this.createProjectForm.get('sections') as FormArray;
    // this.sectionsArray.push(this.sectionForm(section));
    this.sectionsArray.insert(index, this.sectionForm(section));
    const products = section.default_products;
    // const hardwares = section.default_hardwares;
    // const appliances = section.default_appliances;
    if (products.length > 0) {
      products.forEach((p) => {
        // Check if 'p' is an object or an ID
        let productId = p;
        if (typeof p === 'object' && p !== null) {
          // If 'p' is an object, extract its ID
          productId = p._id;
        }
        this.apiService.listProductById(productId).subscribe((res) => {
          this.onAddProduct(res.data, index);
          this.calculateSectionTotal(index);
        });
      });
    }
    // if (hardwares.length > 0) {
    //   hardwares.forEach(hId => {
    //     this.apiService.listHardwareById(hId).subscribe(res => {
    //       this.onAddHardware(res.data, index);
    //       this.calculateSectionTotal(index);
    //     })
    //   })
    // }
    // if (appliances.length > 0) {
    //   appliances.forEach(aId => {
    //     this.apiService.listApplianceById(aId).subscribe(res => {
    //       this.onAddAppliance(res.data, index);
    //       this.calculateSectionTotal(index);
    //     })
    //   })
    // }
  }

  onRemoveSection(i) {
    this.sectionsArray = this.createProjectForm.get('sections') as FormArray;
    this.sectionsArray.removeAt(i);
    this.calculateProjectTotal();
  }

  onAddProduct(product, index) {
    this.productsArray = this.createProjectForm.get([
      'sections',
      index,
      'products',
    ]) as FormArray;
    this.productsArray.push(this.productsForm(product));
    const section = this.createProjectForm.get(['sections', index]) as FormGroup;
    
    // If unit is not set, set it based on the first product's unit
    if (!section.get('unit').value && product.unit) {
      section.get('unit').setValue(product.unit);
    }
  }

  onRemoveProduct(i, j) {
    this.productsArray = this.createProjectForm.get([
      'sections',
      i,
      'products',
    ]) as FormArray;
    this.productsArray.removeAt(j);
    this.calculateSectionTotal(i);
  }

  onSelectProduct(event, i, j) {
    if (event && event.value) {
      this.apiService.listProductById(event.value).subscribe((res) => {
        this.productsArray = this.createProjectForm.get([
          'sections',
          i,
          'products',
        ]) as FormArray;
        const prod = res.data;
        const tempProdArray = this.productsArray.value;
        tempProdArray[j].productName = prod.name;
        tempProdArray[j].image = prod.image;
        tempProdArray[j].materials = [...prod.materials];
        tempProdArray[j].productMaterial = '';
        tempProdArray[j].productMaterialType = '';
        // tempProdArray[j].price = prod.price;
        // tempProdArray[j].mrp = prod.mrp;
        // tempProdArray[j].commission = prod.commission;
        this.productsArray.patchValue(tempProdArray);
      });
    }
  }

  openImageModal(imageUrl) {
    this.dialog.open(ImageMdoalComponent, {
      data: imageUrl,
    });
  }

  onSelectMaterial(material, i, j) {
    this.productsArray = this.createProjectForm.get([
      'sections',
      i,
      'products',
    ]) as FormArray
    const tempProdArray = this.productsArray.value;
    tempProdArray[j].materialTypes = [...material.materialTypes];
    tempProdArray[j].productMaterialType = '';
    tempProdArray[j].productMaterialId = material._id;
    this.productsArray.patchValue(tempProdArray);
  }

  onSelectType(type, i, j, formArrayType) {
    const arr = this.createProjectForm.get([
      'sections',
      i,
      formArrayType,
    ]) as FormArray;
    const tempArray = arr.value;

    // Original price from the product type
    const basePrice = type.price;
    
    let finalPrice = basePrice;

    // Check if the formArrayType is 'products' to apply the increasedProductPricePercent
    if (formArrayType === 'products') {
      // Apply the increased percentage only for products
      const increasedPercent = this.expenseForm.get('increasedProductPricePercent').value;
      finalPrice = basePrice + (basePrice * (increasedPercent / 100));
    }

    // Set the price in the form
    tempArray[j].price = finalPrice;
    
    tempArray[j].commission = type.commission;
    const commission = tempArray[j].commission;

    tempArray[j].commissionType = type.commissionType;
    const commissionType = tempArray[j].commissionType;

    let mrp = 0;
    const price = tempArray[j].price;
    if (commissionType === 'Percent') {
      mrp = price + (price * (commission / 100));
    }
    else {
      mrp = price + commission;
    }

    // Get the associated unit from the material type
    let associatedUnit = type.unit.toLowerCase().replace(/\.$/, '') || '';  // Get unit from the selected material type

    // Set the unit at the section level if not already set
    const section = this.createProjectForm.get(['sections', i]) as FormGroup;
    if (!section.get('unit').value) {
      section.get('unit').setValue(associatedUnit); // Set the unit in the section's form control
    }

    console.log("associated unit",associatedUnit);
        
    // Also, ensure that unit is updated at the product level if needed
    tempArray[j].unit = associatedUnit;
    
    tempArray[j].mrp = mrp;
    tempArray[j].productMaterialTypeId = type._id;
    // tempArray[j].total = type.mrp;
    // tempArray[j].count = 1;
    // tempArray[j].height = 1;
    // tempArray[j].width = 1;
    tempArray[j].total = (
      +tempArray[j].count *
      +tempArray[j].height *
      +tempArray[j].width *
      +tempArray[j].mrp
    ).toFixed(2);
    arr.patchValue(tempArray);
    this.calculateSectionTotal(i);
  }

  onSelectUnit(event, item, i, j) {
    const selectedUnit = event.value; // 'FT.' or 'MTR.'
    console.log(selectedUnit);
    const section = this.createProjectForm.get(['sections', i]) as FormGroup;

    // Set the section's unit
    section.get('unit').setValue(selectedUnit);

    const arr = this.createProjectForm.get([
      'sections',
      i,
      'products',
    ]) as FormArray;
    const tempArray = arr.value;
    tempArray[j].total = (
      +item.count *
      +item.height *
      +item.width *
      +tempArray[j].mrp
    ).toFixed(2);
    // if (event.value === 'FT.') {
    //   tempArray[j].total = (
    //     +item.count *
    //     +item.height *
    //     +item.width *
    //     +tempArray[j].mrp *
    //     (0.305 * 0.305)
    //   ).toFixed(2);
    // } else if (event.value === 'MTR.') {
    //   tempArray[j].total = (
    //     +item.count *
    //     +item.height *
    //     +item.width *
    //     +tempArray[j].mrp
    //   ).toFixed(2);
    // }
    arr.patchValue(tempArray);
    this.calculateSectionTotal(i);
  }

  onSetTotal(item, i, j, formArrayType) {
    const arr = this.createProjectForm.get([
      'sections',
      i,
      formArrayType,
    ]) as FormArray;
    const tempArray = arr.value;
    // tempArray[j].total = (
    //   +item.count *
    //   +item.height *
    //   +item.width *
    //   +tempArray[j].mrp
    // ).toFixed(2);
    if (formArrayType === 'products' && item.unit === 'FT.') {
      tempArray[j].total = (
        +item.count *
        +item.height *
        +item.width *
        +tempArray[j].mrp *
        (0.305 * 0.305)
      ).toFixed(2);
    } else {
      tempArray[j].total = (
        +item.count *
        +item.height *
        +item.width *
        +tempArray[j].mrp
      ).toFixed(2);
    }
    arr.patchValue(tempArray);
    this.calculateSectionTotal(i);
    if (formArrayType === 'appliances') {
      this.setTotalOnDicount(item, i, j);
    }
  }

  onAddHardware(hardware, index) {
    this.hardwaresArray = this.createProjectForm.get([
      'sections',
      index,
      'hardwares',
    ]) as FormArray;
    this.hardwaresArray.push(this.hardwareForm(hardware));
  }

  onRemoveHardware(i, j) {
    this.hardwaresArray = this.createProjectForm.get([
      'sections',
      i,
      'hardwares',
    ]) as FormArray;
    this.hardwaresArray.removeAt(j);
    this.calculateSectionTotal(i);
  }

  onSelectHardware(event, i, j) {
    if (event && event.value) {
      this.apiService.listHardwareById(event.value).subscribe((res) => {
        this.hardwaresArray = this.createProjectForm.get([
          'sections',
          i,
          'hardwares',
        ]) as FormArray;
        const hardware = res.data;
        const tempHardwareArray = this.hardwaresArray.value;
        tempHardwareArray[j].hardwareName = hardware.name;
        tempHardwareArray[j].image = hardware.image;
        tempHardwareArray[j].hardwareTypes = hardware.hardwareType;
        tempHardwareArray[j].price = hardware.price;
        tempHardwareArray[j].mrp = hardware.mrp;
        tempHardwareArray[j].commission = hardware.commission;
        tempHardwareArray[j].total = hardware.mrp;
        tempHardwareArray[j].count = 1;
        tempHardwareArray[j].height = 1;
        tempHardwareArray[j].width = 1;
        this.hardwaresArray.patchValue(tempHardwareArray);
        this.calculateSectionTotal(i);
      });
    }
  }

  onAssociateProduct(event, i, j) {
    if (event && event.value) {
      this.apiService.listProductById(event.value).subscribe((res) => {
        this.hardwaresArray = this.createProjectForm.get([
          'sections',
          i,
          'hardwares',
        ]) as FormArray;
        const product = res.data;
        const tempHardwareArray = this.hardwaresArray.value;
        tempHardwareArray[j].productName = product.name;
        this.hardwaresArray.patchValue(tempHardwareArray);
      });
    }
  }

  onAddAppliance(appliance, index) {
    this.appliancesArray = this.createProjectForm.get([
      'sections',
      index,
      'appliances',
    ]) as FormArray;
    this.appliancesArray.push(this.applianceForm(appliance));
  }

  onRemoveAppliance(i, j) {
    this.appliancesArray = this.createProjectForm.get([
      'sections',
      i,
      'appliances',
    ]) as FormArray;
    this.appliancesArray.removeAt(j);
    this.calculateSectionTotal(i);
  }

  onSelectAppliance(event, i, j) {
    if (event && event.value) {
      this.apiService.listApplianceById(event.value).subscribe((res) => {
        this.appliancesArray = this.createProjectForm.get([
          'sections',
          i,
          'appliances',
        ]) as FormArray;
        const appliance = res.data;
        const tempApplianceArray = this.appliancesArray.value;
        tempApplianceArray[j].applianceName = appliance.name;
        tempApplianceArray[j].image = appliance.image;
        tempApplianceArray[j].applianceTypes = appliance.appliancesType;
        tempApplianceArray[j].price = appliance.price;
        tempApplianceArray[j].mrp = appliance.mrp;
        tempApplianceArray[j].commission = appliance.commission;
        tempApplianceArray[j].total = appliance.mrp;
        tempApplianceArray[j].count = 1;
        tempApplianceArray[j].height = 1;
        tempApplianceArray[j].width = 1;
        this.appliancesArray.patchValue(tempApplianceArray);
        this.calculateSectionTotal(i);
      });
    }
  }

  setTotalOnDicount(item, i, j) {
    const arr = this.createProjectForm.get([
      'sections',
      i,
      'appliances',
    ]) as FormArray;
    const tempArray = arr.value;
    const valueBeforeDiscount =
      +item.count * +item.height * +item.width * +tempArray[j].mrp;
    tempArray[j].total = (
      valueBeforeDiscount -
      valueBeforeDiscount * (+item.discount / 100)
    ).toFixed(2);
    arr.patchValue(tempArray);
    this.calculateSectionTotal(i);
  }

  calculateSectionTotal(i) {
    const prods = this.createProjectForm.get(['sections', i, 'products']).value;
    const hards = this.createProjectForm.get([
      'sections',
      i,
      'hardwares',
    ]).value;
    const apps = this.createProjectForm.get([
      'sections',
      i,
      'appliances',
    ]).value;
    const totalProdAmount = prods.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.total,
      0
    );

    const totalProdMargin = prods.reduce(
      (accumulator, currentValue) =>
        accumulator +
        +(
          (currentValue.mrp - currentValue.price) *
          (currentValue.count * currentValue.height * currentValue.width)
        ),
      0
    );

    const totalHardAmount = hards.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.total,
      0
    );

    const totalHardMargin = hards.reduce(
      (accumulator, currentValue) =>
        accumulator +
        +(
          (currentValue.mrp - currentValue.price) *
          (currentValue.count * currentValue.height * currentValue.width)
        ),
      0
    );

    const totalAppsAmount = apps.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.total,
      0
    );

    const totalAppsMargin = apps.reduce(
      (accumulator, currentValue) =>
        accumulator +
        +(
          (currentValue.mrp -
            currentValue.price -
            ((currentValue.mrp - currentValue.price) / 100) *
              currentValue.discount) *
          (currentValue.count * currentValue.height * currentValue.width)
        ),
      0
    );
    let totalSectionArea = 0;

    prods.forEach(product => {
      if (product.productID && product.productMaterial && product.productMaterialType) {
        const height = parseFloat(product.height) || 0;
        const width = parseFloat(product.width) || 0;
        const count = parseFloat(product.count) || 0;
        const area = height * width * count;

        // Accumulate area based on unit
        totalSectionArea += area;
      }
    });


    const totalSectionProductsControl = this.createProjectForm.get([
      'sections',
      i,
      'productsTotal',
    ]) as FormControl;
    totalSectionProductsControl.setValue(+totalProdAmount);

    const totalSectionHardwareControl = this.createProjectForm.get([
      'sections',
      i,
      'hardwaresTotal',
    ]) as FormControl;
    totalSectionHardwareControl.setValue(+totalHardAmount);

    const totalSectionAmount =
      +totalProdAmount + +totalHardAmount + +totalAppsAmount;
    const totalAmountSectionControl = this.createProjectForm.get([
      'sections',
      i,
      'total',
    ]) as FormControl;
    totalAmountSectionControl.setValue(totalSectionAmount);

    const totalSectionProductsMarginControl = this.createProjectForm.get([
      'sections',
      i,
      'productsMargin',
    ]) as FormControl;
    totalSectionProductsMarginControl.setValue(+totalProdMargin);

    const totalSectionHardwareMarginControl = this.createProjectForm.get([
      'sections',
      i,
      'hardwareMargin',
    ]) as FormControl;
    totalSectionHardwareMarginControl.setValue(+totalHardMargin);

    const totalSectionMargin =
      +totalProdMargin + +totalHardMargin + +totalAppsMargin;
    const totalSectionMarginControl = this.createProjectForm.get([
      'sections',
      i,
      'totalSectionMargin',
    ]) as FormControl;
    totalSectionMarginControl.setValue(totalSectionMargin);

    // **Update Form Controls for the Section**
    const sectionFormGroup = this.createProjectForm.get(['sections', i]) as FormGroup;
    sectionFormGroup.patchValue({
      totalSqft: totalSectionArea, 
    });

    this.calculateProjectTotal();
  }

  calculateProjectTotal() {
    const sections = this.createProjectForm.get('sections').value;

    this.totalProductsAmount = sections.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.productsTotal,
      0
    );

    this.totalHardwareAmount = sections.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.hardwaresTotal,
      0
    );

    this.totalProductsMargin = sections.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.productsMargin,
      0
    );

    this.totalHardwareMargin = sections.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.hardwareMargin,
      0
    );

    this.totalProjectAmount = sections.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.total,
      0
    );

    this.totalProjectMargin = sections.reduce(
      (accumulator, currentValue) =>
        accumulator + +currentValue.totalSectionMargin,
      0
    );

    // **Calculate Total Project Area in Sqft**
    this.totalProjectSqft = sections.reduce((sectionAcc, section) => {
      const sectionArea = parseFloat(section.totalSqft) || 0;
      const unit = section.unit;

      if (unit === 'MTR.') {
        // Convert sqmtr to sqft (1 sqm = 10.7639 sqft)
        return sectionAcc + (sectionArea * 10.7639);
      } else if (unit === 'FT.') {
        // Already in sqft
        return sectionAcc + sectionArea;
      } else {
        // Unknown unit, skip or handle as needed
        return sectionAcc;
      }
    }, 0);

    this.createProjectForm.patchValue({
      finalProductTotal: +this.totalProductsAmount,
    });

    this.createProjectForm.patchValue({
      finalHardwareTotal: +this.totalHardwareAmount,
    });

    this.createProjectForm.patchValue({
      finalProductMargin: +this.totalProductsMargin,
    });

    this.createProjectForm.patchValue({
      finalHardwareMargin: +this.totalHardwareMargin,
    });

    this.createProjectForm.patchValue({
      totalAmount: +this.totalProjectAmount,
    });

    this.createProjectForm.patchValue({
      totalMargin: +this.totalProjectMargin,
    });

    this.createProjectForm.patchValue({
      totalProjectSqft: +this.totalProjectSqft,
    });

  }

  onNextStep(stepName) {
    if (this.createProjectForm.valid) {
      this.sectionsArray = this.createProjectForm.get('sections') as FormArray;

      this.showSectionDiv = false;

      if (stepName === 'hardwareStep' && !this.hardwareStep) {
        this.sectionsArray.value.forEach((s, index) => {
          // const hardwareArrayControl = this.createProjectForm.get(['sections', index, 'hardwares']) as FormArray;
          // hardwareArrayControl.setValidators(Validators.required);
          // hardwareArrayControl.updateValueAndValidity();
          if (s.defaultHardwares.length > 0) {
            s.defaultHardwares.forEach((hId) => {
              this.apiService.listHardwareById(hId).subscribe((res) => {
                this.onAddHardware(res.data, index);
                this.calculateSectionTotal(index);
              });
            });
          }
        });
      }

      if (
        stepName === 'hardwareStep' &&
        this.hardwareStep &&
        this.addSectionAfterForHardware
      ) {
        this.sectionsArray = this.createProjectForm.get(
          'sections'
        ) as FormArray;
        const s = this.sectionsArray.value[this.sectionIndex];
        if (s.defaultHardwares.length > 0) {
          s.defaultHardwares.forEach((hId) => {
            this.apiService.listHardwareById(hId).subscribe((res) => {
              this.onAddHardware(res.data, this.sectionIndex);
              this.calculateSectionTotal(this.sectionIndex);
            });
          });
        }
        this.addSectionAfterForHardware = false;
      }

      if (stepName === 'applianceStep' && !this.applianceStep) {
        this.sectionsArray.value.forEach((s, index) => {
          // const applianceArrayControl = this.createProjectForm.get(['sections', index, 'appliances']) as FormArray;
          // applianceArrayControl.setValidators(Validators.required);
          // applianceArrayControl.updateValueAndValidity();
          if (s.defaultAppliances.length > 0) {
            s.defaultAppliances.forEach((aId) => {
              this.apiService.listApplianceById(aId).subscribe((res) => {
                this.onAddAppliance(res.data, index);
                this.calculateSectionTotal(index);
              });
            });
          }
        });
      }

      if (
        stepName === 'applianceStep' &&
        this.applianceStep &&
        this.addSectionAfterForAppliance
      ) {
        this.sectionsArray = this.createProjectForm.get(
          'sections'
        ) as FormArray;
        const s = this.sectionsArray.value[this.sectionIndex];
        if (s.defaultAppliances.length > 0) {
          s.defaultAppliances.forEach((aId) => {
            this.apiService.listApplianceById(aId).subscribe((res) => {
              this.onAddAppliance(res.data, this.sectionIndex);
              this.calculateSectionTotal(this.sectionIndex);
            });
          });
        }
        this.addSectionAfterForAppliance = false;
      }
    } else {
      this.matSnackBar.open('Please fill all required fields!', 'OK', {
        duration: 3000,
      });
    }
  }

  onBackStep(stepName, stepper: MatStepper) {
    if (this.createProjectForm.valid) {
      this.sectionsArray = this.createProjectForm.get('sections') as FormArray;

      if (stepName === 'hardwareStep') {
        this.hardwareStep = true;
        this.showSectionDiv = true;
        stepper.previous();
        // this.sectionsArray.value.forEach((s, index) => {
        //   const hardwareArrayControl = this.createProjectForm.get(['sections', index, 'hardwares']) as FormArray;
        //   hardwareArrayControl.controls = [];
        //   hardwareArrayControl.clearValidators();
        //   hardwareArrayControl.updateValueAndValidity();
        //   stepper.previous();
        // })
      }

      if (stepName === 'applianceStep') {
        // this.sectionsArray.value.forEach((s, index) => {
        //   const applianceArrayControl = this.createProjectForm.get(['sections', index, 'appliances']) as FormArray;
        //   applianceArrayControl.controls = [];
        //   applianceArrayControl.clearValidators();
        //   applianceArrayControl.updateValueAndValidity();
        //   stepper.previous();
        // })
        this.applianceStep = true;
        stepper.previous();
      }
    } else {
      this.matSnackBar.open('Please fill all required fields!', 'OK', {
        duration: 3000,
      });
    }
  }

  onAddExpenseRow() {
    this.expenseArray = this.expenseForm.get('expenses') as FormArray;
    this.expenseArray.push(this.getExpenseForm());
  }

  onRemoveExpenseRow(i) {
    this.expenseArray = this.expenseForm.get('expenses') as FormArray;
    this.expenseArray.removeAt(i);
    this.calculateExpenseTotal();
  }

  calculateExpenseTotal() {
    const expenses = this.expenseForm.get('expenses').value;

    this.totalExpense = expenses.reduce(
      (accumulator, currentValue) => accumulator + +currentValue.price,
      0
    );

    this.expenseForm.patchValue({
      totalExpense: +this.totalExpense,
    });
  }

  onProductPriceIncreaseChange(increasePercent: string) {
    const increasePercentValue = parseFloat(increasePercent);
    // Check if the field is empty or NaN, then reset the value to 0
    if (!increasePercent || isNaN(increasePercentValue)) {
      this.increasedProductPricePercent = 0;
    } else {
      this.increasedProductPricePercent = increasePercentValue;
    }
    
    this.expenseForm.get('increasedProductPricePercent').setValue(this.increasedProductPricePercent);
    
    (this.createProjectForm.get('sections') as FormArray).controls.forEach((section: FormGroup, index: number) => {
        
      const productsArray = section.get('products') as FormArray;           
      productsArray.controls.forEach((productGroup: FormGroup) => {
        const productId = productGroup.get('productID').value;
        const productMaterialId = productGroup.get('productMaterialId').value;
        const productMaterialTypeId = productGroup.get('productMaterialTypeId').value;
        
        const prod = this.products.find(p => p._id == productId);
        const prodMaterial = prod.materials.find(m => m._id == productMaterialId);
        const prodMaterialType = prodMaterial.materialTypes.find(t => t._id == productMaterialTypeId);
        
        const initialPrice = prodMaterialType.price;
        const increasedPrice = initialPrice + (initialPrice * (this.increasedProductPricePercent / 100));

        productGroup.get('price').setValue(increasedPrice);

        // Recalculate the MRP based on commission
        const commissionType = productGroup.get('commissionType').value;
        const commission = productGroup.get('commission').value;
        let mrp = 0;
        if (commissionType === 'Percent') {
          mrp = increasedPrice + (increasedPrice * (commission / 100));
        }
        else {
          mrp = increasedPrice + commission;
        }
        productGroup.get('mrp').setValue(mrp);

        // Recalculate the total
        const count = productGroup.get('count').value;
        const height =  productGroup.get('height').value;
        const width =  productGroup.get('width').value;
        const total = (
          +count *
          +height *
          +width *
          +mrp
        ).toFixed(2);
        productGroup.get('total').setValue(total);
      });
      // Recalculate section totals after product price update
      this.calculateSectionTotal(index);
    });
    this.calculateExpenseTotal();   
  }

  onCreateProject() {
    if (this.createProjectForm.valid && this.expenseForm.valid) {
      const projectData = {
        ...this.createProjectForm.value,
        ...this.expenseForm.value,
      };

      if (!this.projectID) {
        this.apiService.createProject(projectData).subscribe((res) => {
          if (res && res.data) {
            this.matSnackBar.open('Project Created Successfully !', 'OK', {
              duration: 3000,
            });
            this.router.navigateByUrl('/list-projects');
          } else {
            this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
              duration: 3000,
            });
          }
        });
      } else {
        this.apiService
          .updateProject(this.projectID, projectData)
          .subscribe((res) => {
            if (res && res.data) {
              this.matSnackBar.open('Project Updated Successfully !', 'OK', {
                duration: 3000,
              });
              this.router.navigateByUrl('/list-projects');
            } else {
              this.matSnackBar.open(
                'Some Problem Occurred.. Try Again !',
                'OK',
                { duration: 3000 }
              );
            }
          });
      }
    } else {
      this.matSnackBar.open('Please fill all required fields!', 'OK', {
        duration: 3000,
      });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
